<template>
  <div id="mapp">
    <div id="map"></div>
    <div class="cmp-area">
      <el-button type="primary" @click="showchangeYearsModal">变化年份</el-button>
    </div>
    <virtual-ruler id="rule" ref="ruler" top-border :height="50" :min=-3200 :max="1900" :default-value="currentYear"
      :gap="10" :value-gap="1" @change="ruleChange">
    </virtual-ruler>
    <div class="year-input">
      <el-input-number v-model="currentYear" @change="changeYear" :min="minYear" :max="maxYear" :step="step"
        :step-strictly="true" label="currentYear"></el-input-number>
    </div>
    <el-drawer :visible.sync="drawer" :direction="direction" :modal=false custom-class="info-draw" ref="drawer">
      <div slot="title"><strong>{{ selectPoint.properties?.nameEn }}</strong></div>
      <div>
        <el-form ref="ruleForm" :model="form" :rules="rules" label-width="80px" >
          <el-form-item label="中文名" prop="nameCn">
            <el-input v-model="form.nameCn"></el-input>
          </el-form-item>
          <el-form-item label="英文名" prop="nameEn">
            <el-input v-model="form.nameEn"></el-input>
          </el-form-item>
          <el-form-item label="层级" prop="level">
            <el-input v-model.number="form.level"></el-input>
          </el-form-item>
          <el-form-item label="坐标" prop="coordinates">
            <el-input v-model="form.coordinates"></el-input>
          </el-form-item>
          <el-form-item label="旋转角" prop="textRotate">
            <el-input v-model.number="form.textRotate"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit('ruleForm')">更新</el-button>
            <el-button @click="cancle('')">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-drawer>
    <el-drawer :visible.sync="drawerInfo" direction="rtl" :modal=false custom-class="info-draw">
      <div slot="title"><strong>{{ info.title_en }}</strong></div>
      <p class="info-text">{{ info.text }}</p>
    </el-drawer>
    <el-dialog
        title="提示"
        :visible.sync="changeYearsModalVisible"
        width="30%"
        :close-on-click-modal="false"
        >
        <el-input type="textarea" :rows="10"
            placeholder="变化政权id"
            v-model="changeYearTerritoryIds"></el-input>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="setChangeLayer">确 定</el-button>
        </span>
      </el-dialog>
  </div>
</template>
<script>
/* eslint-disable */
import mapboxgl from "mapbox-gl";
import "../assets/mapbox.css";
import "mapbox-gl-style-switcher/styles.css";
import 'virtual-ruler/dist/virtual-ruler.css';
import VirtualRuler from 'virtual-ruler'
import { Config } from '../config/config.js'
import MapboxDraw from '@mapbox/mapbox-gl-draw'
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
export default {
  data() {
    return {
      zoom: 2,
      minZoom: 0,
      maxZoom: 9,
      map: null,
      drawer: false,
      direction: 'ltr',
      defaultYear: -3200,
      minYear: -3200,
      maxYear: 1900,
      center: [0, 0],
      currentYear: -3200,
      style: null,
      info: {},
      timeoutId: null,
      ruletimeoutId: null,
      keyword: '',
      step: 1,
      draw: null,
      drawerInfo: false,
      selectPoint: {},
      form: {
        nameEn: '', nameCn: '', level: '', coordinates: '',textRotate:''
      },
      rules: {
        nameCn: [
          { required: true, message: '中文名不能为空', trigger: 'blur' }
        ],
        nameEn: [
          { required: true, message: '英文名不能为空', trigger: 'blur' }
        ],
        level: [
          { required: true, message: '层级不能为空', trigger: 'blur' },
          { type: 'number', message: '层级必须为数字值' }
        ],
        coordinates: [
          { required: true, message: '坐标不能为空', trigger: 'blur' }
        ],
        textRotate: [
          { required: true, message: '旋转角度不能为空', trigger: 'blur' },
          { type: 'number', message: '层级必须为数字值' }
        ],
      },
      changeYearsModalVisible:false,
      changeYearTerritoryIds: ''
    };
  },
  components: {
    VirtualRuler,
  },
  methods: {
    async onSubmit(formName) {
      this.form.wayJson = '{"coordinates":[' + this.form.coordinates.split(',')[0] + ',' + this.form.coordinates.split(',')[1] + '],"type":"Point"}'
      this.form.year = this.currentYear
      console.log(this.form)

      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (this.form.id) {
            let { data } = await this.$http.post(Config.host + '/api/point/save/', this.form)
            if (data.code == 200) {
              this.$message({
                message: '更新成功',
                type: 'success'
              });
            }
          } else {
            let { data } = await this.$http.post(Config.host + '/api/point/insert/', this.form)
            if (data.code == 200) {
              this.$message({
                message: '添加成功',
                type: 'success'
              });
            }
          }
        } else {
          this.$message({
            message: '数据不完整',
            type: 'error'
          });
          return false;
        }
      });




    },
    cancle(){
      this.$refs['drawer'].closeDrawer()
    },
    changeYear(e) {
      if (this.timeoutId !== null) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (e == 0) {
          this.currentYear = 1
          this.step = 1
          this.showYear()
          this.$refs.ruler.setValue(1)
        } else if (e >= -3200 && e < -2000) {
          this.currentYear = e
          this.step = 50
          this.showYear()
        }
        else if (e >= -2000 && e < -1000) {
          this.currentYear = e
          this.step = 10
          this.showYear()
        }
        else {
          this.currentYear = e
          this.step = 1
          this.showYear()
        }
      }, 300);
    },
    ruleChange(e) {
      console.log(e)
      if (this.ruletimeoutId !== null) {
        clearTimeout(this.ruletimeoutId);
      }
      this.ruletimeoutId = setTimeout(() => {
        if (e == 0) {
          this.currentYear = 1
          this.step = 1
          this.showYear()
        } else if (e >= -3200 && e < -2000) {
          this.currentYear = e
          this.step = 50
          this.showYear()
        }
        else if (e >= -2000 && e < -1000) {
          this.currentYear = e
          this.step = 10
          this.showYear()
        }
        else {
          this.currentYear = e
          this.step = 1
          this.showYear()
        }
      }, 300);
    },
    format(year) {
      if (year > 0) {
        return 'AD' + year
      } else {
        return 'BC' + (-year)
      }
    },
    async showYear() {
      let year = this.format(this.currentYear)
      console.log(Config.host)
      let { data } = await this.$http.get(Config.host + '/api/tile/style/' + year)
      this.style = data
      console.log(data)
      let { data: res } = await this.$http.get(Config.host + '/api/point/list?year=' + this.currentYear)
      console.log(res['data'])
      this.map = new mapboxgl.Map({
        container: "map",
        style: data,
        pitchWithRotate: false,
        dragRotate: false,
        zoom: this.zoom,
        minZoom: this.minZoom,
        maxZoom: this.maxZoom,
        center: this.center,
        preserveDrawingBuffer: true
      });
      this.map._authenticate = function () { };


      this.map.on("style.load", () => {
        this.map.setFog({});
      });
      this.map.style.glyphManager.localGlyphMode = 0;
      this.draw = new MapboxDraw({
        controls: {
          point: true,
          trash: true
        },
        displayControlsDefault: false,
        styles: [
          {
            'id': 'highlight-active-points',
            'type': 'circle',
            'filter': ['all',
              ['==', '$type', 'Point'],
              ['==', 'meta', 'feature'],
              ['==', 'active', 'true']],
            'paint': {
              'circle-radius': 10,
              'circle-color': 'green'
            }
          },
          {
            'id': 'points-are-blue',
            'type': 'circle',
            'filter': ['all',
              ['==', '$type', 'Point'],
              ['==', 'meta', 'feature'],
              ['==', 'active', 'false']],
            'paint': {
              'circle-radius': 10,
              'circle-color': 'red'
            }
          }
        ]
      })
      this.map.addControl(this.draw);
      const that = this
      this.map.on('load', () => {
        this.map.showCollisionBoxes=true
        this.map.setLayoutProperty('point-level-5','text-allow-overlap',true)
        this.map.setLayoutProperty('point-level-4','text-allow-overlap',true)
        this.map.setLayoutProperty('point-level-3','text-allow-overlap',true)
        this.map.setLayoutProperty('point-level-2','text-allow-overlap',true)
        this.map.setLayoutProperty('point-level-1','text-allow-overlap',true)

        this.map.setLayoutProperty('point-level-5','text-line-height',0.9)
        this.map.setLayoutProperty('point-level-4','text-line-height',0.9)
        this.map.setLayoutProperty('point-level-3','text-line-height',0.9)
        this.map.setLayoutProperty('point-level-2','text-line-height',0.9)
        this.map.setLayoutProperty('point-level-1','text-line-height',0.9)

        


        res['data'].forEach(ele => {
          let tmp = {
            id: 'cus_' + ele.id,
            type: 'Feature',
            properties: { nameCn: ele.nameCn, nameEn: ele.nameEn, level: ele.level, id: ele.id,textRotate:ele.textRotate },
            geometry: JSON.parse(ele.wayJson)
          }
          this.draw.add(tmp)
        });
        this.isCreatePoint = false
        this.map.on('draw.create', (e) => {
          console.log('create')
          console.log(e)
        });
        this.map.on('draw.selectionchange', (e) => {
          e._defaultPrevented =true
          console.log('selectionchange')
          console.log(e)
          if (e.features.length > 0) {
            this.drawer = true
            this.selectPoint = e.features[0]
            this.form.nameCn = this.selectPoint.properties.nameCn
            this.form.nameEn = this.selectPoint.properties.nameEn
            this.form.level = this.selectPoint.properties.level
            this.form.coordinates = this.selectPoint.geometry.coordinates[0] + ',' + this.selectPoint.geometry.coordinates[1]
            this.form.id = this.selectPoint.properties.id
            this.form.textRotate = this.selectPoint.properties.textRotate
          } else {
            this.drawer = false
          }

        });
        this.map.on('draw.update', (e) => {
          console.log('update')
          console.log(e)
          e._defaultPrevented =true
          if (e.features.length > 0) {
            this.drawer = true
            this.selectPoint = e.features[0]
            this.form.nameCn = this.selectPoint.properties.nameCn
            this.form.nameEn = this.selectPoint.properties.nameEn
            this.form.level = this.selectPoint.properties.level
            this.form.coordinates = this.selectPoint.geometry.coordinates[0] + ',' + this.selectPoint.geometry.coordinates[1]
            this.form.id = this.selectPoint.properties.id
            this.form.textRotate = this.selectPoint.properties.textRotate
          } else {
            this.drawer = false
          }
        });
        this.map.on('draw.actionable', (e) => {
          console.log('actionable')
        });
        this.map.on('draw.delete', (e) => {
          console.log('delete')

        });
        this.map.on('draw.modechange', (e) => {
          console.log('modechange')
        });
      })
      this.map.on('zoomend', () => {
        localStorage.setItem('zoom', this.map.getZoom())
      });
      this.map.on('moveend', () => {
        localStorage.setItem('center', "[" + this.map.getCenter().lng + "," + map.getCenter().lat + "]")
      });
  
      this.map.on('click', async (e) => {
          if (e.defaultPrevented) return;
          console.log(e.lngLat);
          const features = this.map.queryRenderedFeatures(
            e.point,
            { layers: ['country', 'country_top', 'country_comb_con'] }
          );
          let popup = null
          if (features.length > 0) {
            console.log(features)
            if (features.length == 1) {
              // this.map.setFilter('country-highlight', ['==', 'territory_id', features[0].properties['territory_id']])
              // this.map.setFilter('country-top-highlight', ['==', 'territory_id', features[0].properties['territory_id']])
              // this.map.setFilter('country-comb-highlight', ['==', 'territory_id', features[0].properties['territory_id']])
              this.info = features[0].properties
              let { data } = await this.$http.get(Config.host + '/api/territory/info?territoryId=' + this.info.territory_id)
              console.log(data)
              this.info['text'] = data.data['descriptionEn']
              that.info['title_en'] = data.data['nameEn']
              this.drawerInfo = true

            } else {
              if (popup) return
              let html = '<div><ul class="pop-ul">'
              for (let i = 0; i < features.length; i++) {
                html += '<li class="comb_popup" tid=' + features[i].properties['territory_id'] + '>' + features[i].properties['title_en'] + '</li>'
              }
              html += '</ul></div>'
              popup = new mapboxgl.Popup({ closeButton: false }).setLngLat(e.lngLat).setHTML(html).addTo(this.map);
              let lis = document.querySelectorAll(".pop-ul li");
              for (let index = 0; index < lis.length; index++) {
                const li = lis[index];
                li.onclick = async function () {
                  let tid = this.getAttribute('tid')
                  // that.map.setFilter('country-highlight', ['==', 'territory_id', tid])
                  // that.map.setFilter('country-top-highlight', ['==', 'territory_id', tid])
                  // that.map.setFilter('country-comb-highlight', ['==', 'territory_id', tid])
                  popup.remove();
                  let { data } = await that.$http.get(Config.host + '/api/territory/info?territoryId=' + tid)
                  console.log(data)
                  that.info['title_en'] = data.data['nameEn']
                  that.info['text'] = data.data['descriptionEn']
                  that.drawerInfo = true
                }
              }
            }

          } else {
            this.drawer = false
            // this.map.setFilter('country-highlight', ['==', 'territory_id', ''])
            // this.map.setFilter('country-top-highlight', ['==', 'territory_id', ''])
            // this.map.setFilter('country-comb-highlight', ['==', 'territory_id', ''])
          }

        });

      window.map = this.map
      window.draw = this.draw
    },
    showchangeYearsModal(){
      this.changeYearsModalVisible =true
    },
    setChangeLayer(){
      let filter = []
      filter.push('in')
      filter.push('territory_id')
      let changeIdsArr = JSON.parse('['+this.changeYearTerritoryIds.trim()+']')
      changeIdsArr.map((item)=>{
        let tmp =item.trim().replaceAll('\n')
        filter.push(tmp)
        return tmp
       })
      this.map.setFilter('country-highlight', filter)
      this.map.setFilter('country-top-highlight', filter)
      this.map.setFilter('country-comb-highlight', filter)
      this.map.setPaintProperty('country-highlight','fill-color','#00ff00')
      this.map.setPaintProperty('country-top-highlight','fill-color','#00ff00')
      this.map.setPaintProperty('country-comb-highlight','fill-color','#00ff00')
      this.changeYearsModalVisible =false
    }
  },
  mounted: async function () {
    if (/Mobi|Android|iPhone/i.test(navigator.userAgent)) {
      this.direction = 'btt'
    }
    if (localStorage.getItem("currentYear")) {
      this.currentYear = Number(localStorage.getItem("currentYear"))
    } else {
      this.currentYear = this.defaultYear
    }
    if (localStorage.getItem("zoom")) {
      this.zoom = Number(localStorage.getItem("zoom"))
    }
    if (localStorage.getItem("center")) {
      this.center = JSON.parse(localStorage.getItem("center"))
    }
    this.showYear()
  },
  watch: {
    currentYear(newVal, oldVal) {
      console.log(newVal)
      localStorage.setItem("currentYear", newVal)
      this.$refs.ruler.setValue(newVal)
    }
  }
};
</script>
<style scoped >
#mapp {
  width: 100%;
  height: 100%;
}

#map {
  width: 100%;
  height: 100%;
}


#rule {
  position: fixed;
  bottom: 0;
  left: 0;
}

.year-input {
  position: fixed;
  bottom: 50px;
  -webkit-appearance: none;
  left: 50%;
  transform: translateX(-50%);
}
.cmp-area{
  position: fixed;
  top: 10px;
  left: 20px;
}
</style>